import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  getAPI(requestUrl: any, status?: any) {
    let reauthentication = localStorage.getItem('reauthentication');
    if (reauthentication && reauthentication !== null) {
      reauthentication = JSON.parse(reauthentication);
    }
    let promise = new Promise((resolve, reject) => {
      firstValueFrom(this.http.get(requestUrl, { observe: 'response' }))
        .then((data: any) => {
          if (status) {
            resolve(data);
          } else {
            resolve(data.body);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            // error = "API not found";
          } else if (error.status === 401) {
            if (!reauthentication) {
              // this.authenticationService.logout();
            }
          } else if (error.status === 500) {
            error = 'Internal Server error';
          } else if (error.status === 504) {
            error = 'API not found';
          } else if (error.status === 502) {
            error = 'API not found';
          } else if (error.status === 506) {
            error = 'API not found';
          }
          reject(error);
        });
    });
    return promise;
  }

  postAPI(requestUrl: any, request: any, status: any) {
    let promise = new Promise((resolve, reject) => {
      firstValueFrom(
        this.http.post(requestUrl, request, { observe: 'response' })
      )
        .then((data: any) => {
          if (status) {
            resolve(data);
          } else {
            resolve(data.body);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            error = 'API not found';
          } else if (error.status === 500) {
            error = 'Internal Server error';
          } else if (error.status === 504) {
            error = 'API not found';
          } else if (error.status === 502) {
            error = 'Bad Gateway';
          } else if (error.status === 506) {
            error = 'API not found';
          }
          reject(error.error);
        });
    });
    return promise;
  }

  putAPI(requestUrl: any, request: any, status: any) {
    let promise = new Promise((resolve, reject) => {
      firstValueFrom(
        this.http.put(requestUrl, request, { observe: 'response' })
      )
        .then((data: any) => {
          if (status) {
            resolve(data);
          } else {
            resolve(data.body);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            error = 'API not found';
          } else if (error.status === 500) {
            error = 'Internal Server error';
          } else if (error.status === 504) {
            error = 'API not found';
          } else if (error.status === 502) {
            error = 'API not found';
          } else if (error.status === 506) {
            error = 'API not found';
          }
          reject(error.error);
        });
    });
    return promise;
  }

  deleteAPI(requestUrl: any, status: any) {
    let promise = new Promise((resolve, reject) => {
      firstValueFrom(this.http.delete(requestUrl, { observe: 'response' }))
        .then((data: any) => {
          if (status) {
            resolve(data);
          } else {
            resolve(data.body);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            error = 'API not found';
          } else if (error.status === 500) {
            error = 'Internal Server error';
          } else if (error.status === 504) {
            error = 'API not found';
          } else if (error.status === 502) {
            error = 'API not found';
          } else if (error.status === 506) {
            error = 'API not found';
          }
          reject(error.error);
        });
    });
    return promise;
  }

  deleteAPIWithRequest(requestUrl: any, request: any, status: any) {
    let options: any = {
      observe: 'response',
      body: request,
    };
    let promise = new Promise((resolve, reject) => {
      firstValueFrom(this.http.delete(requestUrl, options))
        .then((data: any) => {
          if (status) {
            resolve(data);
          } else {
            resolve(data.body);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            error = 'API not found';
          } else if (error.status === 500) {
            error = 'Internal Server error';
          } else if (error.status === 504) {
            error = 'API not found';
          } else if (error.status === 502) {
            error = 'API not found';
          } else if (error.status === 506) {
            error = 'API not found';
          }
          reject(error.error);
        });
    });
    return promise;
  }

  patchAPI(requestUrl: any, request: any, status: any) {
    let promise = new Promise((resolve, reject) => {
      firstValueFrom(
        this.http.patch(requestUrl, request, { observe: 'response' })
      )
        .then((data: any) => {
          if (status) {
            resolve(data);
          } else {
            resolve(data.body);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            error = 'API not found';
          } else if (error.status === 500) {
            error = 'Internal Server error';
          } else if (error.status === 504) {
            error = 'API not found';
          } else if (error.status === 502) {
            error = 'API not found';
          } else if (error.status === 506) {
            error = 'API not found';
          }
          reject(error.error);
        });
    });
    return promise;
  }

  getAPIWithResponseHeaders(requestUrl: any, request?: any) {
    let reauthentication = localStorage.getItem('reauthentication');
    if (reauthentication && reauthentication !== null) {
      reauthentication = JSON.parse(reauthentication);
    }
    let promise = new Promise((resolve, reject) => {
      firstValueFrom(this.http.get(requestUrl, { observe: 'response' }))
        .then((data) => {
          if (request) {
            resolve(data);
          } else if (requestUrl) {
            resolve(data);
          }
        })
        .catch((error) => {
          if (error.status === 401) {
            if (!reauthentication) {
              // this.authenticationService;
              // // .logout();
            }
          }
          reject(error);
        });
    });
    return promise;
  }
}
